@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.tcontainer {
  width: 100%;
  overflow: hidden;
}

.ticker-wrap {
  width: 100%;
  background-color: #A68A6D;
  height: 48px;
  padding-top: 14px;
}

.ticker-move {
  white-space: nowrap;
  animation: ticker 60s linear infinite;
}

.ticker-item {
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  text-align: center;
  line-height: 13px;
}