.quick-search input:focus {
  box-shadow: none;
}

/* .round {
  width: 100%;
  padding: 5px 5px 5px 5px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
} */