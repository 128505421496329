@keyframes cascade-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes cascade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.cascade-item-enter {
  animation: cascade-in 0.2s forwards;
}

.cascade-item-exit {
  animation: cascade-out 0.2s forwards;
}