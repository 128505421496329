@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./components/slider.css');
@import url('./components/default-loader.css');
@import url('./components/quick-search.css');
@import url('./components/modal.css');
@import url('./components/ticker.css');
@import url('./components/react-dates-override.css');
@import url('./components/categories-accordion.css');
@import url('../public/fonts/fonts.css');

body {
  width: 100%;
  @apply font-body;
  @apply text-primary-regular;
  padding-top: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  @apply underline-offset-[0.25em];
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
} */

input:focus {
  box-shadow: none !important;
}

input[type='number'] {
  border: none;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

@layer utilities {
  .text-shadow {
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  }
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}