
@font-face {
  font-family: 'PoppinsBold';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/Poppins-Bold.ttf') format('woff');
}

@font-face {
  font-family: 'PoppinsRegular';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Poppins-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'PoppinsSemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/Poppins-SemiBold.ttf') format('woff');
}

@font-face {
  font-family: 'PoppinsExtraBold';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/Poppins-ExtraBold.ttf') format('woff');
}

@font-face {
  font-family: 'IBMPlexMonoBold';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/IBMPlexMono-Bold.ttf') format('woff');
}

@font-face {
  font-family: 'IBMPlexMonoSemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/IBMPlexMono-SemiBold.ttf') format('woff');
}

@font-face {
  font-family: 'IBMPlexMonoRegular';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/IBMPlexMono-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'BarlowSemiCondensedThin';
  src: url('/fonts/BarlowSemiCondensed-Thin.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'BarlowSemiCondensedSemiBold';
  src: url('/fonts/BarlowSemiCondensed-Semibold.ttf') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'BarlowSemiCondensedExtraLight';
  src: url('/fonts/BarlowSemiCondensed-ExtraLight.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'BarlowSemiCondensedLight';
  src: url('/fonts/BarlowSemiCondensed-Light.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'BarlowSemiCondensedRegular';
  src: url('/fonts/BarlowSemiCondensed-Regular.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratSemiBold';
  src: url('/fonts/Montserrat-SemiBold.ttf') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratMedium';
  src: url('/fonts/Montserrat-SemiBold.ttf') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Montserrat-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'NunitoSansRegular';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/NunitoSans.ttf') format('woff');
}

@font-face {
  font-family: 'NunitoSansSemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/NunitoSans.ttf') format('woff');
}

@font-face {
  font-family: 'NunitoSansBold';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/NunitoSans.ttf') format('woff');
}


/* CHIPOTLE FONTS */

/* GOTHAM */
@font-face {
  font-family: 'GothamBold';
  src: url('/fonts/Gotham-Bold.otf') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'GothamLight';
  src: url('/fonts/Gotham-Light.otf') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GothamMedium';
  src: url('/fonts/Gotham-Medium.otf') format('woff');
  font-weight: 400;
  font-style: normal;
}

/* TRADE GOTHIC */

@font-face {
  font-family: 'TradeGothicBold';
  src: url('/fonts/TradeGothicLTCom-Bold.ttf') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TradeGothicRegular';
  src: url('/fonts/TradeGothicLTCom.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TradeGothicLight';
  src: url('/fonts/TradeGothicLTCom-Light.ttf') format('woff');
  font-weight: 300;
  font-style: normal;
}